<template>
  <div class="contacts">
    <div
      v-for="(item, index) in contactItems"
      :key="index"
      class="contacts-information">
      <div>
        <div class="contacts-information__circle">
          <img
            class="contacts-information__icon"
            :src="item.fields.icon.fields.file.url">
        </div>
      </div>

      <a
        :href="item.fields.link"
        class="contacts-information__link">
        {{ item.fields.linkTitle }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactInformation',
  props: {
    contactItems: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.contacts {
    margin: 50px 0;
    display: flex;

    @media(max-width: $breakpoint-md) {
      flex-direction: column;
      align-items: center;
    }

  &-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
    max-width: 208px;

    @media(max-width: $breakpoint-md) {
      margin-bottom: 40px;
    }

    &__circle {
      padding: 33px;
      width: fit-content;
      border-radius: 50%;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    }

    &__icon {
      width: 57px;
      height: 57px;
    }

    &__link {
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
