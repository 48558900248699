<template>
  <div class="english-page">
    <LoadingSpinner v-if="loading" />

    <template v-else-if="!exception">
      <PageBanner
        :background="content.fields.pageBanner.fields.background"
        :heading="content.fields.pageBanner.fields.heading"
        :subheading="content.fields.pageBanner.fields.subheading" />
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="english-page-title">
              Customer Service
            </h3>

            <ContactInformation :contact-items="content.fields.contacts" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PageBanner from '@/components/views/PageBanner'
import LoadingSpinner from '@/components/LoadingSpinner'
import ContactInformation from '@/components/ContactInformation'

import {
  mapActions,
  mapState
} from 'vuex'

export default {
  components: {
    ContactInformation,
    LoadingSpinner,
    PageBanner
  },
  computed: {
    ...mapState('EnglishPage', [
      'loading',
      'exception',
      'content'
    ])
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('EnglishPage', ['loadContent'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.english-page {
  margin-bottom: 50px;

  &-title {
    margin-top: 50px;
  }
}
</style>
